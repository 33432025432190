import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Leitzins = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Leitzins" showCalc={false} />
            <Article>
                <h2>Was du &uuml;ber den Leitzins wissen solltest</h2>
                <p>
                    Du bist gerade auf der Suche nach einem Haus oder einer Eigentumswohnung und verfolgst deswegen
                    akribisch die t&auml;glichen Entwicklungen auf dem Finanzmarkt. Doch ein einziges Wort, von dem
                    Gl&uuml;ck und Verderben deines Finanzierungsvorhabens auf obskure Weise abzuh&auml;ngen scheinen,
                    l&auml;sst dich immer wieder stutzen: Leitzins. Was es mit diesem auf sich hat und wie sich dessen
                    H&ouml;he auf die Konditionen deiner Finanzierung auswirkt, erf&auml;hrst du im folgenden Beitrag.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was ist der Leitzins eigentlich?</h2>
                <p>
                    Leiht sich eine Gesch&auml;ftsbank Kapital von einer Zentralbank, so fallen auch bei dieser
                    Transaktion Zinsen an. Der{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinssatz
                    </Link>
                    , an dem diese sich dabei orientieren, wird als Leitzins bezeichnet. In der EU ist es die
                    Europ&auml;ische Zentralbank (EZB), die f&uuml;r die Bestimmung des Leitzinses zust&auml;ndig ist.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt Beratungstermin vereinbaren
                    </a>
                </p>
                <hr />

                <h2>So wird der Leitzins bestimmt</h2>
                <p>
                    Im Falle der EZB ist ein eigener Ausschuss f&uuml;r die Festlegung zust&auml;ndig – der EZB-Rat.
                    Dieser reagiert mit der Anpassung des Zinssatzes auf die aktuelle Wirtschaftslage und versucht,
                    bestimmte finanzpolitische Ziele zu erreichen: etwa konstante Preise oder einen allgemeinen
                    wirtschaftlichen Aufschwung. &Uuml;blicherweise werden &Auml;nderungen an zuvor vereinbarten
                    Terminen &ouml;ffentlich gemacht. Im M&auml;rz 2020 trat in den USA allerdings der seltene Fall
                    einer Notfallszinssenkung ein, als die dortige Zentralbank (Fed) den Leitzins aufgrund der
                    wirtschaftlichen Auswirkungen der Corona-Pandemie um 0,5 Prozentpunkte senkte.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Wo welche Leitzinss&auml;tze gelten </h2>
                <p>
                    Der Leitzinssatz der EZB verharrt aktuell mit 0,00 % auf dem niedrigsten Niveau seit 1999 – der
                    Einf&uuml;hrung des Euro. In anderen Wirtschaftsr&auml;umen betr&auml;gt er dagegen mit 10.6.2021:
                </p>
                <ul>
                    <li>Federal Reserve (USA): 0,00-0,25 %</li>
                    <li>Bank of England: 0,10 %</li>
                    <li>Bank of Japan: -0,10 %</li>
                    <li>Chinesische Volksbanken: 3,85 %</li>
                    <li>Schweizer Nationalbank: -0,75 %</li>
                </ul>
                <p>
                    Die &Ouml;sterreichische Nationalbank bietet dir auf ihrer Website &uuml;brigens die
                    M&ouml;glichkeit, die Entwicklung ausgew&auml;hlter Leitzinss&auml;tze seit 1980 zu verfolgen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Welche unterschiedlichen EZB-Leitzinss&auml;tze gibt es?</h2>
                <p>
                    In Hinblick auf den Leitzins innerhalb der EU sorgt nicht selten der Umstand f&uuml;r Verwirrung,
                    dass man hier zwischen drei verschiedenen S&auml;tzen unterscheiden muss. Neben dem
                    Hauptrefinanzierungszinssatz, der die{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinsen
                    </Link>{" "}
                    festlegt, die Banken zu bezahlen haben, wenn sie sich Kapital von der EZB leihen, gibt es den
                    Einlagesatz oder Einlagenzins: Dieser Begriff bezieht sich auf jenen Zinssatz, zu dem
                    Gesch&auml;ftsbanken Kapital bei der EZB anlegen k&ouml;nnen. Aktuell betr&auml;gt er -0,50 %. Der
                    Spitzenrefinazierungszinssatz bestimmt schlie&szlig;lich den Zinssatz, zu dem Banken kurzfristig
                    Geld von der EZB erhalten. Er betr&auml;gt derzeit 0,25 %.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Zum Expertengespräch!
                    </a>
                </p>
                <hr />

                <h2>Die Auswirkungen des Leitzinses auf Immobilienkredite </h2>
                <p>
                    K&ouml;nnen sich Banken ihr Kapital kosteng&uuml;nstig beschaffen, so steigt auch ihre eigene
                    Bereitschaft,{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredite
                    </Link>{" "}
                    zu vergleichsweise g&uuml;nstigen Konditionen zu vergeben. Auch weil der Leitzinssatz Auswirkungen
                    auf den EURIBOR hat, der bei variabler Verzinsung oft als Referenz dient, sp&uuml;rst du als
                    Kreditnehmer*in in diesem Fall etwaige Ver&auml;nderungen.
                </p>
                <p>
                    Folglich profitieren gerade Kund*innen, die sich f&uuml;r variable{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinsen
                    </Link>{" "}
                    entschieden haben, aktuell von dem historisch niedrigen Stand des EZB-Leitzinses. Bei Wahl eines
                    Zinsmodells f&uuml;r deine Finanzierung solltest du daher bedenken, dass eine Erh&ouml;hung des
                    Leitzinses innerhalb der EU vor 2023 eher unwahrscheinlich ist.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern!
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"leitzins"}></BreadcrumbList>
            <ArticleStructuredData page={"leitzins"} heading={"Leitzins"} />
        </Layout>
    );
};

export default Leitzins;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.leitzins", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
